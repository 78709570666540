[data-lk-theme=default] {

  --lk-bg: #f5f4f8;
  --lk-bg2: #dbd9e3;
  --lk-bg3: #e1dfe6;
  --lk-bg4: #d2cfd9;
  --lk-bg5: #aeacb6;
  --lk-fg: #100b24;
  --lk-fg2: #0b0818;
  --lk-fg3: #0b0818;
  --lk-fg4: #0b0818;
  --lk-fg5: #0b0818;
  
  --lk-border-color: rgba(255, 255, 255, 0.1);
  
  --lk-accent-fg: #fff;
  --lk-accent-bg: #30216c;
  /* 
  --lk-accent2: #{color.adjust($accent, $lightness: 4%)};
  --lk-accent3: #{color.adjust($accent, $lightness: 8%)};
  --lk-accent4: #{color.adjust($accent, $lightness: 12%)};

  --lk-danger-fg: #fff;
  --lk-danger: #f91f31;
  --lk-danger2: #{color.adjust($danger, $lightness: 4%)};
  --lk-danger3: #{color.adjust($danger, $lightness: 8%)};
  --lk-danger4: #{color.adjust($danger, $lightness: 12%)};

  --lk-success-fg: #fff;
  --lk-success: #1ff968;
  --lk-success2: #{color.adjust($success, $lightness: 4%)};
  --lk-success3: #{color.adjust($success, $lightness: 8%)};
  --lk-success4: #{color.adjust($success, $lightness: 12%)};
*/
  --lk-control-fg: var(--lk-fg);
  --lk-control-bg: var(--lk-bg2);
  
  --lk-control-hover-bg: var(--lk-bg3);
  --lk-control-active-bg: var(--lk-bg4);
  --lk-control-active-hover-bg: var(--lk-bg5);
/*
  --lk-connection-excellent: #06db4d;
  --lk-connection-good: #f9b11f;
  --lk-connection-poor: #f91f31;

  --lk-font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  --lk-font-size: 16px;
  --lk-line-height: 1.5;
  --lk-border-radius: 0.5rem;
  --lk-box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);

  --lk-grid-gap: 0.5rem;
  --lk-control-bar-height: 69px;
  --lk-chat-header-height: 69px; */
}

.corner-logo {
  height: 55px;
  margin-bottom: 1rem;
  margin-left: 1rem;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  display: none;
}
@media only screen and (min-width: 1080px) {
  .corner-logo {
    display: block;
  }
}

input {
  color: var(--lk-control-fg);
}
.lk-participant-metadata-item {
  color: var(--lk-bg);
}

* {
  box-sizing: border-box;
}

html {
  color-scheme: light;
  /* color-scheme: dark; */
  /* background-color: #f5f4f8; */
}

html,
body {
  overflow: hidden;
}

html,
body,
#__next,
main {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.header {
  max-width: 500px;
  padding-inline: 2rem;
}

.header > img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.header > h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}
